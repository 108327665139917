<template>
    <div>
      <h1>趣優line app 測試</h1>
      <button @click="getProfile">获取个人资料</button>
      <br><br>
      <button @click="geCamera">打开扫码</button>
      <div v-if="profile">
        <h2>个人资料</h2>
        <p>用户 ID：{{ profile.userId }}</p>
        <p>用户名称：{{ profile.displayName }}</p>
        <p>用户头像：<img :src="profile.pictureUrl" alt="头像"></p>
      </div>
      <div v-if="scanRes">{{scanRes}}</div>
    </div>
</template>

<script>
import liff from "@line/liff";

export default {
    name: 'LineComponent',
    data() {
        return {
            profile: null,
            scanRes: null,
        };
    },
    mounted() {
        console.log(`the component is now mounted.`)

        liff.init({ liffId: '1661440255-mR5dj4WX' })
        .then(async () => {
            if (liff.isLoggedIn()) {
                console.log('liff:',liff.getProfile());
            } else {
                liff.login();
                // console.log('liff2222:', liff.getContext());
            }
        })
        .catch((err) => {
            console.log(err);
        })

    },
  methods: {
    async getProfile() {
          try {
            // print the environment in which the LIFF app is running
                console.log(liff.getLanguage());
                console.log(liff.getVersion());
                console.log(liff.isInClient());
                console.log(liff.isLoggedIn());
                console.log(liff.getOS());
                console.log(liff.getLineVersion());
            
              console.log('LIFF2:', liff);
              console.log('liff2222:',liff.getContext());
            // await liff.init({ liffId: "1661440255-mR5dj4WX" });
              const profile = await liff.getProfile();
            console.log('profile:',profile);
            this.profile = profile;
      } catch (error) {
        console.error(error);
      }
    },
    async geCamera() {
      try {

        let res = await liff.scanCodeV2();
        this.scanRes = res

      } catch (error) {

        console.error(error);
      }
    }
  },
}
</script>

<style scoped></style>